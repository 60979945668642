/* eslint-disable */
import React from 'react';
import Layout from '../components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Experiencetab from '../components/Experiencetab';
import Gettouch from '../components/Gettouch';
import Headinfo from '../components/Headinfo';
import Meeting from '../components/Meeting';
import DataMaturityAssessmentTop from '../components/datamaturityassessmenttop';
import Louisbester from '../assets/images/Louis-bester.jpg';
import Stephangrobler from '../assets/images/Stephan-grobler.jpg';
import { Helmet } from 'react-helmet';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const datamaturity = (props) => {
  const datamaturityImages = useStaticQuery(graphql`
    query datamaturityImages {
      louis: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/louis.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      jay: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/jay.png/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      anton: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/anton.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <div className='experienceurbianpage orange'>
      <Layout>
        <Helmet>
          <title>Urbian | Data maturity assessment</title>
        </Helmet>
        <DataMaturityAssessmentTop></DataMaturityAssessmentTop>
        <Container className='spb-5 border-bottom'>
          <Row>
            <Col lg='6'>
              <div className='sp-5'>
                <h3>What is it?</h3>
                <p>
                  This workshop introduces a data maturity scale and helps you
                  see where your organisation is sitting.
                </p>
                <p>
                  Together, we'll explore your current landscape and plot out a
                  roadmap to enable data-driven decision-making. And we'll
                  create an initial strategy for taking your business to the
                  next maturity level.
                </p>
              </div>
              <div className='sp-5'>
                <h3>Who is it for?</h3>
                <p>
                  Product and eCommerce leaders who need to make the best use of
                  their data.
                </p>
              </div>
              <div className='sp-5'>
                <h3>What will you learn?</h3>
                <ul>
                  <li>Understand the potential of customer data</li>
                  <li>Assess how your organisation is using it today</li>
                  <li>
                    Explore how to accelerate your maturity and why it matters
                  </li>
                </ul>
              </div>
              <h3>Who will run it?</h3>
              <p>A Product Owner or Delivery Lead.</p>
              <div className='team-grid'>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={datamaturityImages.louis.childImageSharp.sizes}
                      alt='Louis Bester'
                    />
                  </div>
                  <h6>Louis Bester</h6>
                  <span>Product Owner</span>
                </div>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={datamaturityImages.jay.childImageSharp.sizes}
                      alt='Jay Thomson'
                    />
                  </div>
                  <h6>Jay Thomson</h6>
                  <span>Managing Partner</span>
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <Meeting location={props.location.pathname}></Meeting>
            </Col>
          </Row>
        </Container>
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    </div>
  );
};
export default datamaturity;
